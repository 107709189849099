<div class="banner-area-two">
    <div class="container-fluid">
        <div class="container-max-2">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="banner-content-two">
                        <span>{{ 'HOME.BANNER.ELEMENT' | translate }}</span>
                        <h1>{{ 'HOME.BANNER.TITLE' | translate }}</h1>
                        <p>{{ 'HOME.BANNER.TEXT' | translate }}</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="banner-img-2">
                        <img src="assets/img/home-two/home-two-img.png" alt="Images">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-bottom-shape">
        <div class="shape-one wow fadeInUp" data-wow-delay=".3s"><img src="assets/img/home-two/home-two-shape1.png" alt="Images"></div>
        <div class="shape-two wow fadeInUp" data-wow-delay=".5s"><img src="assets/img/home-two/home-two-shape2.png" alt="Images"></div>
        <div class="shape-three wow fadeInUp" data-wow-delay=".7s"><img src="assets/img/home-two/home-two-shape3.png" alt="Images"></div>
        <div class="banner-dots1"><img src="assets/img/home-two/home-two-dots.png" alt="Images"></div>
        <div class="banner-dots2"><img src="assets/img/home-two/home-two-dots2.png" alt="Images"></div>
    </div>
</div>

<div class="service-area-two">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">{{ 'HOME.FEATURES.NAME' | translate }}</span>
            <h2>{{ 'HOME.FEATURES.TITLE' | translate }}</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <h3>{{ 'HOME.FEATURES.SKILLS.FIRST.TITLE' | translate }}</h3>
                    <div class="services-item-img">
                        <span><img src="assets/img/service/service-icon7.png" alt="Images"></span>
                        <div class="img-circle"></div>
                    </div>
                    <p>{{ 'HOME.FEATURES.SKILLS.FIRST.DESCRIPTION' | translate }}</p>
                    <a routerLink="/blog" class="learn-btn">{{ 'HOME.FEATURES.BUTTON' | translate }} <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <h3>{{ 'HOME.FEATURES.SKILLS.SECOND.TITLE' | translate }}</h3>
                    <div class="services-item-img">
                        <span><img src="assets/img/service/service-icon8.png" alt="Images"></span>
                        <div class="img-circle"></div>
                    </div>
                    <p>{{ 'HOME.FEATURES.SKILLS.SECOND.DESCRIPTION' | translate }}</p>
                    <a routerLink="/blog" class="learn-btn">{{ 'HOME.FEATURES.BUTTON' | translate }} <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="services-item">
                    <h3>{{ 'HOME.FEATURES.SKILLS.THIRD.TITLE' | translate }}</h3>
                    <div class="services-item-img">
                        <span><img src="assets/img/service/service-icon9.png" alt="Images"></span>
                        <div class="img-circle"></div>
                    </div>
                    <p>{{ 'HOME.FEATURES.SKILLS.THIRD.DESCRIPTION' | translate }}</p>
                    <a routerLink="/blog" class="learn-btn">{{ 'HOME.FEATURES.BUTTON' | translate }} <i class='bx bx-plus'></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="service-two-shape">
        <div class="shape-in1"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape-in2"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape-in3"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape-in4"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape-in5"><img src="assets/img/shape/shape12.png" alt="Images"></div>
    </div>
</div>

<div class="about-area-two pb-70">
    <div class="container-fluid">
        <div class="container-max">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-img-2">
                        <img src="assets/img/about/about-img2.png" alt="">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-content-two">
                        <div class="section-title">
                            <span class="sp-before sp-after">{{ 'HOME.ABOUT.NAME' | translate }}</span>
                            <h2>{{ 'HOME.ABOUT.TITLE' | translate }}</h2>
                        </div>
                        <h3>{{ 'HOME.ABOUT.SUBTITLE' | translate }}</h3>
                        <p>{{ 'HOME.ABOUT.DESCRIPTION' | translate }}</p>
                        <div class="row">
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>01</span>
                                    <p>{{ 'HOME.ABOUT.SOLUTIONS.FIRST' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>02</span>
                                    <p>{{ 'HOME.ABOUT.SOLUTIONS.SECOND' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>04</span>
                                    <p>{{ 'HOME.ABOUT.SOLUTIONS.THIRD' | translate }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="team-area-two pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">{{ 'HOME.TEAM.NAME' | translate }}</span>
            <h2 class="h2-color2">{{ 'HOME.TEAM.TITLE' | translate }}</h2>
        </div>

        <div class="team-wrapper pt-45">
            <div class="team-card">
                <div class="team-photo">
                    <span class="team-img team-bg-before-3"><img src="assets/img/team/photo_2024-06-04_18-14-59.jpg" alt="Images"></span>
                </div>
                <div class="content">
                    <h3>{{ 'HOME.TEAM.SLIDES.FIRST.FULLNAME' | translate }}</h3>
                    <span>{{ 'HOME.TEAM.SLIDES.FIRST.POSITION' | translate }}</span>
                    <p>{{ 'HOME.TEAM.SLIDES.FIRST.INFO' | translate }}</p>
                </div>
            </div>

            <div class="team-card">
                <div class="team-photo">
                    <span class="team-img team-bg-before"><img src="assets/img/team/k_shorokhov.jpg" alt="Images"></span>
                </div>
                <div class="content">
                    <h3>{{ 'HOME.TEAM.SLIDES.SECOND.FULLNAME' | translate }}</h3>
                    <span>{{ 'HOME.TEAM.SLIDES.SECOND.POSITION' | translate }}</span>
                    <p>{{ 'HOME.TEAM.SLIDES.SECOND.INFO' | translate }}</p>
                </div>
            </div>

            <div class="team-card">
                <div class="team-photo">
                    <span class="team-img team-bg-before-3"><img src="assets/img/team/a_poletova.jpg" alt="Images"></span>
                </div>
                <div class="content">
                    <h3>{{ 'HOME.TEAM.SLIDES.THIRD.FULLNAME' | translate }}</h3>
                    <span>{{ 'HOME.TEAM.SLIDES.THIRD.POSITION' | translate }}</span>
                    <p>{{ 'HOME.TEAM.SLIDES.THIRD.INFO' | translate }}</p>
                </div>
            </div>

            <div class="team-card">
                <div class="team-photo">
                    <span class="team-img team-bg-before"><img src="assets/img/team/d_belousov.jpg" alt="Images"></span>
                </div>
                <div class="content">
                    <h3>{{ 'HOME.TEAM.SLIDES.FOURTH.FULLNAME' | translate }}</h3>
                    <span>{{ 'HOME.TEAM.SLIDES.FOURTH.POSITION' | translate }}</span>
                    <p>{{ 'HOME.TEAM.SLIDES.FOURTH.INFO' | translate }}</p>
                </div>
            </div>
        </div>
    </div>

    <div class="team-two-shape">
        <div class="shape1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape12.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape7"><img src="assets/img/shape/shape14.png" alt="Images"></div>
    </div>
</div>

<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">{{ 'HOME.PORTFOLIO.NAME' | translate }}</span>
            <h2 class="h2-color2">{{ 'HOME.PORTFOLIO.TITLE' | translate }}</h2>
        </div>

        <div class="row pt-45">
            @for (item of blog; track $index) {
                <div class="col-lg-4 col-md-6">
                    <div class="blog-card">
                        <a [routerLink]="['/blog-details', item.id]">
                            <img [src]="item.preview.url" [alt]="item.preview.alt">
                        </a>
                        <div class="content">
                            <ul>
                                <li><i class='bx bx-time-five'></i>{{item.date | date : 'dd.MM.y'}}</li>
                            </ul>
                            <h3>
                                <a [routerLink]="['/blog-details', item.id]">{{translate.currentLang === 'en' ? item.en.title : item.ru.title}}</a>
                            </h3>
                        </div>
                    </div>
                </div>
            }
        </div>
        <div class="blog-btn">
            <a routerLink="/blog" class="default-btn">{{ 'HOME.PORTFOLIO.BUTTON' | translate }}<i class='bx bx-plus'></i></a>
        </div>
    </div>

    <div class="blog-shape">
        <div class="shape1"><img src="assets/img/shape/shape1.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
    </div>
</div>

<div class="data-table-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="data-table-content">
                    <h2>{{ 'HOME.EXTRA.TITLE' | translate }}</h2>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="data-table-img">
                    <img src="assets/img/shape/footer-bg-new.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>