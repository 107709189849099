<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/logo/esys-logo.svg" alt="Logo"></a>
    </div>
    <div class="main-nav nav-bar-two">
        <div class="container-fluid">
            <nav class="container-max-2 navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo/esys-logo-group-white.svg" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item">
                            <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">{{ 'NAVBAR.MENU.HOME' | translate }}</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/blog" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{ 'NAVBAR.MENU.PROJECTS' | translate }}</a>
                        </li>
                    </ul>

                    <div class="side-nav d-in-line align-items-center">
                        <div class="side-item lang-item">
                            <button class="lang-btn" (click)="isDropdownShow = !isDropdownShow">
                                @if (translate.currentLang === 'en') {
                                    <span class="lang-btn-en"></span>
                                } @else if (translate.currentLang === 'ru') {
                                    <span class="lang-btn-ru"></span>
                                }
                            </button>
                            @if (isDropdownShow) {
                                <ul class="dropdown">
                                    <li class="dropdown-item">
                                        <button (click)="translate.use('en'); isDropdownShow = !isDropdownShow">English</button>
                                    </li>
                                    <li class="dropdown-item">
                                        <button (click)="translate.use('ru'); isDropdownShow = !isDropdownShow">Русский</button>
                                    </li>
                                </ul>
                            }
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="side-nav-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="circle-inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>
            <div class="container">
                <div class="side-nav-inner">
                    <div class="side-nav justify-content-center  align-items-center">
                        <div class="side-item">
                            <button class="lang-btn" (click)="isDropdownShow = !isDropdownShow">
                                @if (translate.currentLang === 'en') {
                                    <span class="lang-btn-en"></span>
                                } @else if (translate.currentLang === 'ru') {
                                    <span class="lang-btn-ru"></span>
                                }
                            </button>
                            @if (isDropdownShow) {
                                <ul class="dropdown">
                                    <li class="dropdown-item">
                                        <button (click)="translate.use('en'); isDropdownShow = !isDropdownShow">English</button>
                                    </li>
                                    <li class="dropdown-item">
                                        <button (click)="translate.use('ru'); isDropdownShow = !isDropdownShow">Русский</button>
                                    </li>
                                </ul>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>