<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{articleLang.title}}</h3>

            <ul class="inner-list">
                <li><i class='bx bx-user'></i> {{articleLang.author}}</li>
                <li><i class='bx bx-calendar'></i> {{article.date | date : 'dd.MM.y'}}</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots-2"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="blog-details-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-article">
                    <div class="blog-article-img">
                        <img [src]="article.preview.url" [alt]="article.preview.alt">
                    </div>
                    <div class="content-text">
                        <p>{{articleLang.introduction}}</p>
                    </div>
                    <div class="article-content">
                        @for (item of articleLang.stages; track $index) {
                            <h2>{{item.stage}}</h2>
                            @for (detail of item.details; track $index) {
                                <p>{{detail}}</p>
                            }
                            @if (item.img) {
                                <div  class="content-img">
                                    <div class="blog-article-img">
                                        <img [src]="item.img.url" [alt]="item.img.alt">
                                    </div>
                                </div>
                            }
                        }
                        <div>
                            <h2>{{'ARTICLE.COMPLETED' | translate}}</h2>
                            <ul>
                                @for (task of articleLang.completedTasks; track $index) {
                                    <li>{{task}}</li>
                                }
                            </ul>
                        </div>
                        <div>
                            <h2>{{'ARTICLE.CONCLUSION' | translate}}</h2>
                            <p>{{articleLang.conclusion}}</p>
                        </div>
                    </div>
                    <div class="blog-article-share">
                        <div class="row align-items-center">
                            <div class="col-lg-7 col-sm-6 col-md-7">
                                <div class="blog-tag">
                                    <ul>
                                        <li>Tags:</li>
                                        @for (tag of article.tags; track $index) {
                                            <li><a routerLink="">#{{tag}}</a></li>
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-5 col-sm-6 col-md-5">
                                <ul class="social-icon">
                                    <li><a target="_blank" [href]="article.link"><i class="bx bxl-netlify"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>