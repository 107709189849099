<footer class="footer-area footer-bg2 pt-100">
    <div class="container">
        <div class="footer-midal pb-70">
            <div class="row">
                <div class="col-lg-4 col-sm-7">
                    <div class="footer-widget">
                        <div class="footer-img">
                            <img src="assets/img/logo/esys-logo-group.svg" alt="Images">
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-7">
                    <div class="footer-widget pl-5">
                        <h3>{{ 'FOOTER.COMPANY.COMPANY' | translate }}</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/">{{ 'FOOTER.COMPANY.HOME' | translate }}</a></li>
                            <li><a routerLink="/blog">{{ 'FOOTER.COMPANY.OUR_PROJECTS' | translate }}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>