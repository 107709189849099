import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-navbar-style-two',
  templateUrl: './navbar-style-two.component.html',
  styleUrls: ['./navbar-style-two.component.scss']
})
export class NavbarStyleTwoComponent implements OnInit {
  isDropdownShow: boolean = false;

  constructor(public translate: TranslateService) { }

  ngOnInit(): void {
    this.translate.setDefaultLang(this.translate.getBrowserLang());
    this.translate.use(this.translate.getBrowserLang());
  }

}
