import { Injectable, Signal, WritableSignal, computed, signal } from '@angular/core';
import mock from '../mock/blog.json';
import { Article, Blog } from '../model/blog';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  blogData: WritableSignal<Blog> = signal<Blog>(mock);
  articles: Signal<Article[]> = computed(() => this.blogData().articles);

  constructor() { }

  getArticleById(id: number): Article {
    return this.articles().find(elem => elem.id === id);
  }
}
