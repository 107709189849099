import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BlogService } from '../../../services/blog.service';
import { Article } from 'src/app/model/blog';

@Component({
  selector: 'app-ai-startup',
  templateUrl: './ai-startup.component.html',
  styleUrls: ['./ai-startup.component.scss']
})
export class AiStartupComponent implements OnInit {
  public blog: Article[];
  constructor(public translate: TranslateService, private blogService: BlogService) { }

  ngOnInit(): void {
    this.blog = this.blogService.articles();
  }

}
