import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Article } from 'src/app/model/blog';
import { BlogService } from 'src/app/services/blog.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  public blog: Article[];
  constructor(public translate: TranslateService, private blogService: BlogService) { }

  ngOnInit(): void {
    this.blog = this.blogService.articles();
  }

}
