import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Article, ArticleContent } from 'src/app/model/blog';
import { BlogService } from 'src/app/services/blog.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlogDetailsComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  id: number;
  currentLang: string;
  article: Article;
  articleLang: ArticleContent;

  constructor(private activateRoute: ActivatedRoute, public translate: TranslateService, private blogService: BlogService, private cd: ChangeDetectorRef) {
    this.activateRoute.params.subscribe(params => (this.id = Number(params['id'])));
  }

  ngOnInit(): void {
    this.currentLang = this.translate.getBrowserLang();
    this.article = this.blogService.getArticleById(this.id);
    const { en, ru } = this.article;
    if (this.currentLang === 'en')
      this.articleLang = en;
    else
      this.articleLang = ru;
    this.translate.onLangChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(res => {
      this.currentLang = res.lang;
      if (this.currentLang === 'en')
        this.articleLang = en;
      else
        this.articleLang = ru;
      this.cd.markForCheck();
    })
  }

}
