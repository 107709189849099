<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{ 'PROJECTS.TITLE' | translate }}</h3>
            <ul>
                <li><a routerLink="/">{{ 'PROJECTS.BREADCRUMBS.HOME' | translate }}</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>{{ 'PROJECTS.BREADCRUMBS.PROJECTS' | translate }}</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="blog-area pt-50 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">{{ 'PROJECTS.NAME' | translate }}</span>
            <h2 class="h2-color2">{{ 'PROJECTS.SUBTITLE' | translate }}</h2>
        </div>

        <div class="row pt-45">
            @for (item of blog; track $index) {
                <div class="col-lg-4 col-md-6">
                    <div class="blog-card">
                        <a [routerLink]="['/blog-details', item.id]">
                            <img [src]="item.preview.url" [alt]="item.preview.alt">
                        </a>
                        <div class="content">
                            <ul>
                                <li><i class='bx bx-time-five'></i>{{item.date | date : 'dd.MM.y'}}</li>
                            </ul>
                            <h3>
                                <a [routerLink]="['/blog-details', item.id]">{{translate.currentLang === 'en' ? item.en.title : item.ru.title}}</a>
                            </h3>
                        </div>
                    </div>
                </div>
            }

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center">
                    <a routerLink="/blog" class="prev page-numbers"><i class="bx bx-chevron-left"></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/blog" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="blog-shape">
        <div class="shape1"><img src="assets/img/shape/shape1.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
    </div>
</div>