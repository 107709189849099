import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AiStartupComponent } from './components/pages/ai-startup/ai-startup.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';

const routes: Routes = [
    {path: '', component: AiStartupComponent},
    {path: 'blog', component: BlogComponent},
    {path: 'blog-details/:id', component: BlogDetailsComponent},
    {path: '**', component: ErrorComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }